import React from "react";
import NavigationPane from "../../components/common/NavigationPane";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import BarChartLayout from "../../components/layout/BarChartLayout";

const DashboardHome = () => {
  const homeItems = [
    {
      bgColor: "bg-red-500",
      count: "count",
      label: "Dues - Amount: count",
      info: "More info",
    },
    {
      bgColor: "bg-blue-500",
      count: "count",
      label: "Total income this year",
      info: "More info",
    },
    {
      bgColor: "bg-orange-500",
      count: "count",
      label: "income this month",
      info: "More info",
    },
    {
      bgColor: "bg-green-500",
      count: "count",
      label: "income today",
      info: "More info",
    },
    {
      bgColor: "bg-yellow-500",
      count: "count",
      label: "profit this month",
      info: "More info",
    },
    {
      bgColor: "bg-green-500",
      count: "count",
      label: "total expense this year",
      info: "More info",
    },
    {
      bgColor: "bg-blue-500",
      count: "count",
      label: "expense this month",
      info: "More info",
    },
    {
      bgColor: "bg-red-500",
      count: "count",
      label: "expense today",
      info: "More info",
    },
  ];

  const feeData = [
    { name: "Jan", bar1: 4000, bar2: 2400 },
    { name: "Feb", bar1: 3000, bar2: 1398 },
    { name: "Mar", bar1: 2000, bar2: 9800 },
    { name: "Apr", bar1: 2780, bar2: 3908 },
  ];

  return (
    <div className="flex flex-col gap-y-5">
      <NavigationPane />

      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {homeItems.map((item, index) => (
          <div
            key={index}
            className={`shadow px-3 py-2 rounded flex flex-col text-white ${item.bgColor}`}
          >
            <div className="text-xl font-bold">{item.count}</div>
            <div className="text-lg capitalize">{item.label}</div>
            <div className="flex items-center gap-1">
              <span>{item.info}</span>
              <MdOutlineArrowCircleRight />
            </div>
          </div>
        ))}
      </div>

      <div className="flex">
        <BarChartLayout
          data={feeData}
          dataKeys={["bar1", "bar2"]}
          xAxisKey="name"
          width="100%"
          height={400}
          colors={["#8884d8", "#82ca9d"]}
          showGrid={true}
          showLegend={true}
          barSize={30} // Custom width for the bars
        />

        <div></div>
      </div>
    </div>
  );
};

export default DashboardHome;
