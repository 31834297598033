import React, { useState } from "react";
import { FaUsers, FaChalkboardTeacher } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"; // Add icons for dropdown
import { NavLink } from "react-router-dom";
import { useDashboard } from "../layout/DashboardLayout";
import Tippy from "@tippyjs/react";

const Sidebar: React.FC = () => {
  const { isSidebarOpen, isOpen } = useDashboard();
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {}
  );

  const toggleDropdown = (label: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const navItems = [
    {
      path: "/dashboard",
      icon: <MdOutlineDashboard />,
      label: "Dashboard",
      tooltip: "Go to Dashboard",
    },
    {
      path: "/dashboard/students",
      icon: <FaUsers />,
      label: "Students",
      tooltip: "Manage Students",
      dropdown: [
        { path: "/dashboard/students/students-list", label: "Student List" },
        { path: "/dashboard/students/add", label: "Add Student" },
      ],
    },
    {
      path: "/dashboard/teachers",
      icon: <FaChalkboardTeacher />,
      label: "Teachers",
      tooltip: "Manage Teachers",
    },
  ];

  return (
    <aside className="fixed left-0">
      <div
        className={`bg-blue-800 text-white h-screen lg:flex flex-col transition-all duration-300 pb-5 ${
          isSidebarOpen ? "hidden" : `fixed left-0 translate-x-0 flex`
        } ${
          isOpen ? `w-44 justify-between` : `w-20 justify-center`
        } transition-transform transform z-[1001] overflow-visible`}
      >
        {/* Scrollable Content Wrapper */}
        <div className="flex-1 overflow-visible scrollbar">
          <ul className="mt-4 space-y-4 px-2">
            {navItems.map((item, index) => (
              <li key={index} className="relative group">
                {/* Tooltip */}
                <Tippy
                  content={item.tooltip}
                  placement="right"
                  arrow={true}
                  className={`${
                    isOpen ? "hidden" : item.dropdown ? "hidden" : "block"
                  }`}
                >
                  {/* NavLink */}
                  <NavLink
                    to={!item.dropdown ? item.path : ""} // Remove "to" when it's a dropdown
                    className={({ isActive }) =>
                      `flex items-center p-2 rounded hover:bg-blue-600 ${
                        isActive && !item.dropdown ? "bg-blue-700" : ""
                      } ${isOpen ? "gap-x-4" : "justify-center"}`
                    }
                    onClick={(e) => {
                      if (item.dropdown) {
                        e.preventDefault(); // Prevent navigation for dropdown
                        toggleDropdown(item.label); // Toggle dropdown
                      }
                    }}
                  >
                    <span className="text-2xl">{item.icon}</span>
                    <span
                      className={`text-sm font-medium transition-opacity ${
                        isOpen ? "block" : "hidden"
                      }`}
                    >
                      {item.label}
                    </span>
                    {isOpen && item.dropdown && (
                      <span className="ml-auto text-xl">
                        {openDropdowns[item.label] ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                      </span>
                    )}
                  </NavLink>
                </Tippy>

                {/* Dropdown Menu */}
                {item.dropdown && (
                  <ul
                    className={`pl-8 mt-1 space-y-1 ${
                      openDropdowns[item.label] && isOpen ? "block" : "hidden"
                    }`}
                  >
                    {item.dropdown.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) =>
                            `block p-2 rounded hover:bg-blue-600 ${
                              isActive ? "bg-blue-700" : ""
                            }`
                          }
                        >
                          {subItem.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Hover-based dropdown for collapsed sidebar */}
                {item.dropdown && !isOpen && (
                  <div
                    className="absolute left-full top-0 hidden group-hover:block"
                    style={{
                      backgroundColor: "rgba(29, 78, 216)", // Blue background for the dropdown
                      borderRadius: "0.375rem", // Rounded corners
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add some shadow
                      zIndex: 1050, // Ensure dropdown is on top of the sidebar
                      width: "max-content", // Ensure dropdown width adjusts based on content
                    }}
                  >
                    <ul className="p-2">
                      {item.dropdown.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <NavLink
                            to={subItem.path}
                            className="block px-2 py-1 hover:bg-blue-600 rounded"
                          >
                            {subItem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
