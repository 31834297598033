export const validateUsername = (username: string): string | null => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;

  if (!username.trim()) {
    return "Name is required.";
  }

  if (!alphanumericRegex.test(username)) {
    return "Name must conatin only alphabets and numbers.";
  }

  return null; // No validation errors
};

export const validatePassword = (password: string): string | null => {
  if (!password.trim()) {
    return "Password is required.";
  }

  return null; // No validation errors
};
