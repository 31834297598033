import React, { createContext, useContext, useState } from "react";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";

// Define the context type
interface DashboardContextProps {
  isSidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined
);

// Custom hook for accessing the context
export const useDashboard = (): DashboardContextProps => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within DashboardLayout");
  }
  return context;
};

type DashboardLayoutProps = React.PropsWithChildren<{}>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  return (
    <DashboardContext.Provider
      value={{ isSidebarOpen, setSidebarOpen, isOpen, setIsOpen }}
    >
      <div>
        <Header />

        <div className="absolute top-[64px] w-full flex bg-gray-100">
          <Sidebar />

          <div
            className={`p-6 flex-1 transition-all ${
              isSidebarOpen
                ? isOpen
                  ? "lg:ml-[176px]"
                  : "lg:ml-[80px]"
                : "lg:ml-0"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

export default DashboardLayout;
