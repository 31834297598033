import React from "react";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import Tippy from "@tippyjs/react";

const NavigationPane: React.FC = () => {
  const navItems = [
    {
      path: "/dashboard",
      icon: <MdOutlineDashboard />,
      label: "Dashboard",
      tooltip: "Go to Dashboard",
    },
    {
      path: "/dashboard/students",
      icon: <FaUsers />,
      label: "Students",
      tooltip: "Manage Students",
    },
    {
      path: "/dashboard/teachers",
      icon: <FaChalkboardTeacher />,
      label: "Teachers",
      tooltip: "Manage Teachers",
    },
  ];

  return (
    <div className="bg-blue-800 text-white px-4 rounded py-2">
      <ul className="flex items-center justify-center gap-x-2">
        {navItems.map((item, index) => (
          <li key={index}>
            {/* Tooltip */}
            <Tippy content={item.tooltip} placement="bottom" arrow={true}>
              <NavLink
                to={item.path}
                className={`flex items-center p-2 rounded bg-red-400 hover:bg-red-500 my-anchor-element`}
              >
                <span className="text-2xl">{item.icon}</span>
              </NavLink>
            </Tippy>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationPane;
