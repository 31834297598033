import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/api";
import "../assets/styles/login.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Button from "../components/common/Button";
import { validateUsername, validatePassword } from "../utils/validation";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setValidationErrors({ username: "", password: "" });

    // Perform validations
    const usernameError = validateUsername(username);
    const passwordError = validatePassword(password);

    if (usernameError || passwordError) {
      setValidationErrors({
        username: usernameError || "",
        password: passwordError || "",
      });
      return;
    }

    try {
      const response = await loginUser(username, password);
      navigate("/dashboard");
    } catch (err: any) {
      if (err && typeof err === "object" && err.error) {
        setError(`Error: ${err.error}`);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="bg-[url('../images/classroom-pic.jpg')] bg-cover bg-center w-[100%] min-h-screen">
      <div className="login-sec flex items-center justify-center w-[100%] min-h-screen py-2">
        <div className="wrapper w-[300px] md:w-[350px]">
          <form action="#" className="flex flex-col" onSubmit={handleSubmit}>
            <h2 className="text-3xl mb-4">Login</h2>
            {error && <p style={{ color: "red" }}>{error}</p>}

            <div className="input-field">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label>Enter your name</label>
            </div>

            <div className="input-field">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="pswd-hide-icon"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </span>
              <label>Enter your password</label>
            </div>

            <p style={{ color: "red", fontSize: "0.8rem" }}>
              {(() => {
                if (validationErrors.username) {
                  if (validationErrors.password) return "Fill in the Fields";
                  else return validationErrors.username;
                } else if (validationErrors.password) {
                  return validationErrors.password;
                }
              })()}
            </p>

            <div className="forget text-right">
              <a href="#">Forgot password?</a>
            </div>

            <Button label="Log In" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
