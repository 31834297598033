// BarChartLayout.tsx
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid, // Import CartesianGrid to show grid lines in the chart
  Tooltip,
  Legend,
  ResponsiveContainer, // Import ResponsiveContainer to make the chart responsive
} from "recharts";

interface BarChartLayoutProps {
  data: object[]; // Data for the bar chart
  dataKeys: string[]; // Array of data keys for each bar (to render multiple bars)
  xAxisKey: string; // The key for the X-axis data (typically for the category names)
  width?: string | number; // Width of the chart, can be a percentage or pixel value
  height?: string | number; // Height of the chart, can be a percentage or pixel value
  colors?: string[]; // Array of colors for the bars
  showGrid?: boolean; // Whether to show the grid lines in the chart
  showLegend?: boolean; // Whether to display the legend
  barSize?: number; // The width of the bars, customizable (default is 20)
}

const BarChartLayout: React.FC<BarChartLayoutProps> = ({
  data,
  dataKeys,
  xAxisKey,
  width = "100%",
  height = 400,
  colors = ["#8884d8", "#82ca9d"],
  showGrid = true,
  showLegend = true,
  barSize = 20,
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart data={data}>
        {showGrid && <CartesianGrid strokeDasharray="3 3" />}
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip />
        {showLegend && <Legend />}
        {dataKeys.map((key, index) => (
          <Bar
            key={index}
            dataKey={key}
            fill={colors[index % colors.length]} // Set the bar color from the colors array, cycling through the colors if more than 2 bars
            barSize={barSize} // Apply the custom bar size (width)
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartLayout;
