import React, { useState } from "react";
import { FaEnvelope, FaHeart, FaPlusCircle, FaRegUser } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { RiFileListLine, RiUserAddFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { TiArrowSortedDown } from "react-icons/ti";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { MdClose, MdMenu } from "react-icons/md";
import { useDashboard } from "../layout/DashboardLayout";
import Tippy from "@tippyjs/react";

const Header: React.FC = () => {
  const { isSidebarOpen, setSidebarOpen, isOpen, setIsOpen } = useDashboard();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCampusDropdownOpen, setCampusDropdownOpen] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState("Main Campus");
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filters");

  const campusOptions = ["Main Campus", "North Campus", "South Campus"];

  const FilterOptions = ["Filters", "Option 1", "Option 2"];

  // Define menu items in a single array
  const menuItems = [
    {
      label: "Add a new item",
      icon: <FaPlusCircle className="text-gray-600 cursor-pointer" />,
      tooltip: "Add a new item",
    },
    {
      label: "Favorites",
      icon: <FaHeart className="text-gray-600 cursor-pointer" />,
      tooltip: "Favorites",
    },
    {
      label: "Messages",
      icon: <FaEnvelope className="text-gray-600 cursor-pointer" />,
      tooltip: "Messages",
    },
    {
      label: "Tasks",
      icon: <RiFileListLine className="text-gray-600 cursor-pointer" />,
      tooltip: "Tasks",
    },
    {
      label: "Add a user",
      icon: <RiUserAddFill className="text-gray-600 cursor-pointer" />,
      tooltip: "Add a user",
    },
  ];

  const navigate = useNavigate();

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <header className="fixed top-0 bg-white z-[1000] w-full">
      <nav
        className={`flex lg:items-center lg:justify-normal h-16 ${
          isMenuOpen && "flex-col py-4"
        }`}
      >
        <div
          className={`bg-blue-800 lg:flex lg:h-full h-[64px] items-center px-4 py-4 transition-all duration-300 ${
            isSidebarOpen ? "hidden" : `fixed top-0 left-0 translate-x-0 flex`
          } ${
            isOpen ? `w-44 justify-between` : `w-20 justify-center`
          } transition-transform transform `}
        >
          {isOpen ? (
            <>
              <h1
                className={`text-xl text-white font-bold transition-opacity ${
                  isOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                EMS
              </h1>
              <Button
                label={<MdClose size={24} />}
                onClick={() =>
                  isSidebarOpen ? setIsOpen(!isOpen) : setSidebarOpen(true)
                }
                className="text-white focus:outline-none"
              />
            </>
          ) : (
            <Button
              label={<MdMenu size={24} />}
              onClick={() => setIsOpen(!isOpen)}
              className="text-white focus:outline-none"
            />
          )}
        </div>

        <div className="lg:hidden w-full flex flex-1 justify-between items-center px-9">
          <Button
            label={<MdMenu />}
            onClick={() => {
              setSidebarOpen(!isSidebarOpen);
              setIsOpen(isOpen);
            }}
            className="text-2xl lg:hidden"
          />

          <div className="text-2xl font-bold lg:hidden">EMS</div>

          <Button
            label={
              <>
                <FiMoreVertical />
              </>
            }
            onClick={() => setMenuOpen(!isMenuOpen)}
            className="text-2xl lg:hidden"
          />
        </div>

        <div
          className={`lg:flex flex-1 items-center lg:px-3 xl:px-6  ${
            isMenuOpen ? `py-6 px-9 bg-white w-full` : "hidden"
          }`}
        >
          <ul
            className={`flex flex-1 lg:items-center lg:gap-x-2 xl:gap-x-5 ${
              isMenuOpen ? "flex-col gap-y-3 mb-4" : ""
            }`}
          >
            <div className="flex items-center gap-x-2 xl:w-1/2">
              <input
                type="text"
                placeholder="Find Students, Staff, Parents..."
                className="p-2 border flex-1 border-gray-300 rounded-md w-2/3 md:w-1/3 xl:w-1/2"
              />
              <div className="relative">
                <Button
                  label={
                    <>
                      <span className="truncate">{selectedFilter}</span>
                      <TiArrowSortedDown />
                    </>
                  }
                  onClick={() => setFilterDropdownOpen(!isFilterDropdownOpen)}
                  className={`bg-gray-600 py-1 px-3 text-white rounded focus:outline-none flex items-center gap-x-1 truncate ${
                    isOpen && `lg:max-w-[110px] xl:max-w-max`
                  } `}
                />
                {isFilterDropdownOpen && (
                  <div className="absolute mt-2 w-40 bg-white shadow-lg rounded-md z-10">
                    {FilterOptions.map((option, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedFilter(option);
                          setFilterDropdownOpen(false);
                        }}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="relative">
              <Button
                label={
                  <>
                    <span className="truncate">{selectedCampus}</span>
                    <TiArrowSortedDown />
                  </>
                }
                onClick={() => setCampusDropdownOpen(!isCampusDropdownOpen)}
                className={`bg-blue-800 py-1 px-3 text-white rounded focus:outline-none flex items-center gap-x-1 truncate ${
                  isOpen && `lg:max-w-[110px] xl:max-w-max`
                }`}
              />
              {isCampusDropdownOpen && (
                <div className="absolute mt-2 w-40 bg-white shadow-lg rounded-md z-10">
                  {campusOptions.map((campus, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedCampus(campus);
                        setCampusDropdownOpen(false);
                      }}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                    >
                      {campus}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </ul>
          <ul
            className={`flex lg:items-center lg:gap-x-4 xl:gap-x-7 ${
              isMenuOpen ? "flex-col gap-y-3" : ""
            }`}
          >
            {isMenuOpen ? (
              menuItems.map((item, index) => (
                <span key={index} className="flex items-center gap-x-3">
                  {item.icon} {item.label}
                </span>
              ))
            ) : (
              <ul className="flex items-center lg:gap-x-4 xl:gap-x-7">
                {menuItems.map((item, index) => (
                  <li key={index} className="relative flex items-center">
                    {/* Tooltip */}
                    <Tippy
                      content={item.tooltip}
                      placement="bottom"
                      arrow={true}
                    >
                      <div
                        data-tooltip-id={`menu-tooltip-${index}`} // Unique ID for each tooltip
                        data-tooltip-content={item.tooltip} // Tooltip content
                        className="cursor-pointer"
                        data-tooltip-place="bottom"
                      >
                        {item.icon}
                      </div>
                    </Tippy>
                  </li>
                ))}
              </ul>
            )}

            <div className="flex items-center gap-x-2">
              <FaRegUser />
              <span className="font-semibold">Admin</span>
            </div>

            <Button
              label={
                <>
                  Log Out <TbLogout />
                </>
              }
              onClick={handleLogout}
              className="flex items-center gap-x-2 text-red-500 font-bold"
            />
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
