type ButtonProps = {
  label: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>; // Allow async functions
  className?: string; // Optional custom class for further styling
};

const Button: React.FC<ButtonProps> = ({ label, onClick, className }) => (
  <button onClick={onClick} className={className}>
    {label}
  </button>
);

export default Button;
