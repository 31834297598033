import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL +
  "/users/loginsecuser?" +
  "username=edu01&password=ems@12345";

export const loginUser = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      username,
      password,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw error.response.data; // Pass the error data to the caller
    }
    throw new Error("An unexpected error occurred.");
  }
};
