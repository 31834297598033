import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Default styles
import LoginPage from "./pages/Login";
import DashboardLayout from "./components/layout/DashboardLayout";
import DashboardHome from "./pages/dashboard/DashboardHome";
import TeachersPage from "./pages/dashboard/TeachersPage";
import StudentsList from "./pages/dashboard/StudentsList";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/dashboard/*"
          element={
            <DashboardLayout>
              <Routes>
                <Route path="" element={<DashboardHome />} />
                <Route
                  path="students/students-list"
                  element={<StudentsList />}
                />
                <Route path="teachers" element={<TeachersPage />} />
              </Routes>
            </DashboardLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
